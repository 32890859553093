import {Injectable} from '@angular/core'
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http'
import {finalize, Observable} from 'rxjs'

import {LoaderState} from '@wv/states/loader.state'
import {HTTP_PARAM_SHOW_LOADER} from '@ui/constants/constants'

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {

  constructor(
    private loaderService: LoaderState,
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!request.params.has(HTTP_PARAM_SHOW_LOADER)) {
      return next.handle(request)
    }

    this.loaderService.push(request.url)

    return next.handle(
      request.clone({
        params: request.params.delete(HTTP_PARAM_SHOW_LOADER),
      }),
    ).pipe(
      finalize(() => {
        this.loaderService.pop(request.url)
      }),
    )
  }
}
