import {Injectable} from '@angular/core'
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router'

import {rnLog} from '@ui/utils/rn-webview'
import {EduState} from '@wv/states/edu.state'

@Injectable()
export class EduGuard implements CanActivate {

  constructor(
    private router: Router,
    private eduState: EduState,
  ) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    if (this.eduState.hasCourse) {
      rnLog('[EduGuard] The student has a course. Opening the course module...')
      return true
    }

    this.router.navigate(['/no-course'])
    return false
  }
}
