import {Injectable} from '@angular/core'

@Injectable({
  providedIn: 'root',
})
export class LoaderState {

  queue: string[] = []

  push(event: string): void {
    this.queue.push(event)
  }

  pop(event: string): void {
    const foundIndex = this.queue.findIndex(i => i === event)
    if (foundIndex !== -1) {
      this.queue.splice(foundIndex, 1)
    }
  }
}
