import {NgModule} from '@angular/core'
import {RouterModule, Routes} from '@angular/router'

import {AppGuard} from '@wv/guards/app.guard'
import {EduGuard} from '@wv/guards/edu.guard'

const routes: Routes = [
  {
    path: '',
    canActivate: [AppGuard],
    children: [
      {
        path: '',
        canActivate: [EduGuard],
        children: [
          {
            path: '',
            loadChildren: () => import('./pages/main/main.module').then(r => r.MainModule),
          },
          {
            path: 'subjects',
            loadChildren: () => import('./pages/subjects/subjects.module').then(r => r.SubjectsModule),
          },
          {
            path: 'subjects/:subjectId/lessons/:lessonId',
            loadChildren: () => import('./pages/lesson/lesson.module').then(r => r.LessonModule),
          },
          {
            path: 'subjects/:subjectId/lessons/:lessonId/broadcasts',
            loadChildren: () => import('./pages/broadcast/broadcast.module').then(r => r.BroadcastModule),
          },
          {
            path: 'subjects/:subjectId/lessons/:lessonId/homework',
            loadChildren: () => import('./pages/homework/homework.module').then(r => r.HomeworkModule),
          },
          {
            path: 'today-lessons',
            loadChildren: () => import('./pages/today-lessons/today-lessons.module').then(r => r.TodayLessonsModule),
          },
          {
            path: 'today-homeworks',
            loadChildren: () => import('./pages/today-homeworks/today-homeworks.module').then(r => r.TodayHomeworksModule),
          },
          {
            path: 'trainer',
            loadChildren: () => import('./pages/trainer/trainer.module').then(r => r.TrainerModule),
          },
          {
            path: 'indep/subjects',
            loadComponent: () => import('./pages/indep-subjects/indep-subjects.component').then(r => r.IndepSubjectsComponent),
          },
          {
            path: 'indep/subjects/:masterSubjectId/discounts',
            loadComponent: () => import('./pages/indep-subject-discounts/indep-subject-discounts.component').then(r => r.IndepSubjectDiscountsComponent),
          },
          {
            path: 'indep/subjects/:masterSubjectId/stream-subjects/:streamSubjectId/modules/:moduleId/lessons',
            loadComponent: () => import('./pages/indep-lessons/indep-lessons.component').then(r => r.IndepLessonsComponent),
          },
          {
            path: 'indep/subjects/:masterSubjectId/stream-subjects/:streamSubjectId/modules/:moduleId/lessons/:lessonId',
            loadChildren: () => import('./pages/indep-lesson/indep-lesson.module').then(r => r.IndepLessonModule),
          },
          {
            path: 'indep/subjects/:masterSubjectId/stream-subjects/:streamSubjectId/modules/:moduleId/lessons/:lessonId/lecture',
            loadChildren: () => import('./pages/indep-lecture/indep-lecture.module').then(r => r.IndepLectureModule),
          },
          {
            path: 'indep/subjects/:masterSubjectId/stream-subjects/:streamSubjectId/modules/:moduleId/lessons/:lessonId/homework',
            loadChildren: () => import('./pages/indep-homework/indep-homework.module').then(r => r.IndepHomeworkModule),
          },
          {
            path: 'indep/subjects/:masterSubjectId/stream-subjects/:streamSubjectId/tests/:testId/start',
            loadComponent: () => import('./pages/indep-stream-test/start/start.component').then(r => r.StartComponent),
          },
          {
            path: 'indep/subjects/:masterSubjectId/stream-subjects/:streamSubjectId/tests/:testId/test',
            loadComponent: () => import('./pages/indep-stream-test/test/test.component').then(r => r.TestComponent),
          },
          {
            path: 'indep/subjects/:masterSubjectId/stream-subjects/:streamSubjectId/tests/:testId/result',
            loadComponent: () => import('./pages/indep-stream-test/result/result.component').then(r => r.ResultComponent),
          },
        ],
      },
      {
        path: 'error',
        loadChildren: () => import('./pages/error/error.module').then(r => r.ErrorModule),
      },
      {
        path: 'no-course',
        loadComponent: () => import('./pages/no-course/no-course.component').then(r => r.NoCourseComponent),
      },
      {
        path: 'indep/buy-course/:subjectId',
        loadChildren: () => import('./pages/indep-buy-course/indep-buy-course.module').then(r => r.IndepBuyCourseModule),
      },
      {
        path: 'weekly-test',
        loadChildren: () => import('./pages/weekly-test/weekly-test.module').then(r => r.WeeklyTestModule),
      },
      {
        path: 'trial-test',
        loadChildren: () => import('./pages/trial-test/trial-test.module').then(r => r.TrialTestModule),
      },
      {
        path: 'banners',
        children: [
          {
            path: 'course/subjects',
            children: [
              {
                path: '',
                loadComponent: () => import('./pages/no-course/no-course.component').then(r => r.NoCourseComponent),
              },
              {
                path: ':subjectId',
                loadChildren: () => import('./pages/indep-buy-course/indep-buy-course.module').then(r => r.IndepBuyCourseModule),
              },
            ],
          },
          {
            path: 'admission/universities/:universityId',
            loadComponent: () => import('./pages/admission/admission.component').then(r => r.AdmissionComponent),
          },
        ],
      },
    ],
  },
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
