import {Component} from '@angular/core'

import {LoaderState} from '@wv/states/loader.state'

@Component({
  selector: 'core-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent {

  constructor(
    public loaderService: LoaderState,
  ) {
  }
}
