import {Injectable} from '@angular/core'
import {HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http'
import {TranslateService} from '@ngx-translate/core'
import {catchError, Observable, Subject, switchMap, take, tap, throwError} from 'rxjs'
import moment from 'moment'

import {env} from '@ui/env'

import {AppState} from '@wv/states/app.state'
import {HTTP_PARAM_FETCHING_MEDIA, HTTP_PARAM_SKIP_ERROR_HANDLE} from '@ui/constants/constants'
import {rnWebviewPostMessage, rnWebviewPostMessageLog} from '@ui/utils/rn-webview'

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  isRefreshingTokenInProgress: boolean

  onFinishRefreshingToken: Subject<boolean>
  onFinishRefreshingToken$: Observable<boolean>

  constructor(
    private appService: AppState,
    private translateService: TranslateService,
  ) {
    this.isRefreshingTokenInProgress = false

    this.onFinishRefreshingToken = new Subject()
    this.onFinishRefreshingToken$ = this.onFinishRefreshingToken.asObservable()
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    rnWebviewPostMessageLog(`Request url: ${request.url}`)

    request = this.applyRequestHeaders(request)

    if (this.isTokenExpired()) {
      return this.launchRefreshToken(request, next)
    }

    return next.handle(request)
      .pipe(
        catchError(error => {
          return this.handleResponseError(error, request, next)
        }),
      )
  }

  applyRequestHeaders(request: HttpRequest<any>): HttpRequest<any> {
    if (request.params.has(HTTP_PARAM_FETCHING_MEDIA)) {
      return request
    }

    const lang = this.appService.lang
    if (lang) {
      request = request.clone({
        setHeaders: {'Accept-Language': lang},
      })
    }

    if (request.url.startsWith(env.apiHost)) {
      const token = this.appService.token
      if (token) {
        return request.clone({
          setHeaders: {Authorization: `Bearer ${token}`},
        })
      }
    }

    return request
  }

  isTokenExpired(): boolean {
    const accessTokenExpireDate = this.appService.tokenExpireMoment
    const leftSeconds = moment().diff(accessTokenExpireDate, 'seconds')
    rnWebviewPostMessageLog(`Access token expiration left seconds: ${leftSeconds}}`)
    return leftSeconds > -35
  }

  handleResponseError(error: HttpErrorResponse, request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    rnWebviewPostMessageLog({url: request.url, error})

    if (request.params.has(HTTP_PARAM_SKIP_ERROR_HANDLE)) {
      return throwError(error)
    }

    if (error.status === 401) {
      return this.launchRefreshToken(request, next)
    }

    let message: string
    if (error.status >= 500) {
      message = this.translateService.instant('common.server_error')
    } else if (error.status === 404) {
      message = this.translateService.instant('common.not_found')
    } else {
      message = this.extractHttpErrorMessage(error.error)
    }

    alert(message)

    return throwError(() => error)
  }

  launchRefreshToken(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return this.refreshToken(request)
      .pipe(
        switchMap(() => {
          request = this.applyRequestHeaders(request)
          return next.handle(request)
        }),
        catchError(error => {
          return throwError(error)
        }),
      )
  }

  refreshToken(request: HttpRequest<any>): Observable<any> {
    if (this.isRefreshingTokenInProgress) {
      rnWebviewPostMessageLog(`Waits for refresh: ${request.url}`)

      return this.onFinishRefreshingToken$
        .pipe(
          take(1),
          tap(() => {
            rnWebviewPostMessageLog(`Finished waiting - re-launch request: ${request.url}`)
          }),
        )
    } else {
      this.isRefreshingTokenInProgress = true

      rnWebviewPostMessage({
        event: 'on_refresh_token',
        data: request.url,
      })

      rnWebviewPostMessageLog(`Try to refresh token: ${request.url}`)

      return this.appService.onMobileAppFinishRefreshingToken$
        .pipe(
          take(1),
          tap(() => {
            this.isRefreshingTokenInProgress = false
            this.onFinishRefreshingToken.next(false)

            rnWebviewPostMessageLog(`Successfully refreshed - update tokens: ${request.url}`)
          }),
        )
    }
  }

  extractHttpErrorMessage(data: any): string {
    if (!data || typeof data === 'string') {
      return data
    }
    if (Array.isArray(data)) {
      return this.extractHttpErrorMessage(data[0])
    } else {
      const objKeys = Object.keys(data)
      return this.extractHttpErrorMessage(data[objKeys[0]])
    }
  }
}
