import {NgModule} from '@angular/core'
import {BrowserModule} from '@angular/platform-browser'
import {BrowserAnimationsModule} from '@angular/platform-browser/animations'
import {HashLocationStrategy, LocationStrategy} from '@angular/common'
import {register} from 'swiper/element/bundle'

import {AppRoutingModule} from './app-routing.module'
import {AppComponent} from './app.component'
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http'
import {ModalModule} from 'ngx-bootstrap/modal'
import {TranslateModule} from '@ngx-translate/core'
import {InsetsDirectiveModule} from '@ui/directives/wv/insets/insets-directive.module'
import {SafeHtmlPipeModule} from '@wv/pipes/safe-html/safe-html-pipe.module'
import {BottomSheetComponent} from '@wv/components/bottom-sheet/bottom-sheet.component'
import {LoaderComponent} from '@wv/components/loader/loader.component'
import {DialogConfirmComponent} from '@wv/components/dialog-confirm/dialog-confirm.component'
import {EduTypePickerComponent} from '@wv/components/edu-type-picker/edu-type-picker.component'
import {StarGainModalComponent} from '@wv/components/star-gain-modal/star-gain-modal.component'
import {LoaderInterceptor} from '@wv/interceptors/loader.intercepter'
import {AuthInterceptor} from '@wv/interceptors/auth.interceptor'
import {AppGuard} from '@wv/guards/app.guard'
import {EduGuard} from '@wv/guards/edu.guard'

register()

@NgModule({
  declarations: [
    AppComponent,
    LoaderComponent,
    DialogConfirmComponent,
    EduTypePickerComponent,
    StarGainModalComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ModalModule.forRoot(),
    TranslateModule.forRoot(),
    AppRoutingModule,
    InsetsDirectiveModule,
    SafeHtmlPipeModule,
    BottomSheetComponent,
  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    AppGuard,
    EduGuard,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
