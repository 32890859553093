import {Component} from '@angular/core'
import {NavigationStart, Router, RouterEvent} from '@angular/router'
import {filter} from 'rxjs'

import {rnWebviewPostMessageOnNavigate} from '@ui/utils/rn-webview'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {

  constructor(
    private router: Router,
  ) {
    this.initNavigationChange()
  }

  initNavigationChange(): void {
    this.router.events
      .pipe(
        filter((e: any) => e instanceof RouterEvent && e instanceof NavigationStart),
      )
      .subscribe(({url}: RouterEvent) => {
        let payload = {
          canGoBack: true,
          scrollEnabled: false,
          tabBarVisible: false,
          orientation: 'lockToPortrait',
        }
        switch (true) {
          case url === '/':
          case url === '/no-course':
          case url.startsWith('/error'):
            payload = {...payload, canGoBack: false, tabBarVisible: true}
            break
          case url.startsWith('/banners'):
            payload = {...payload, canGoBack: true, tabBarVisible: false}
            break
        }
        rnWebviewPostMessageOnNavigate(payload)
      })
  }
}
